import React from "react";

class CurriculumExperis extends React.Component {
    render() {
        return (
            <section className="send-cv">
                <div className="container">
                    <div className="row">
                        <div className="col col-lg-12">
                            <h3>¿No encontraste oportunidades que coincidan con tu perfil?</h3>
                            <a href="https://apply.worcket.com/?id=48300cd2-3dab-4172-9644-e457b6105a09"  rel="noopener noreferrer" target="_blank "className="btn">Cargá tu CV</a>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default CurriculumExperis;
import React from 'react'
import AssessmentIconGren from '../../../../assets/images/pages/assessment-icon-green.svg';
import ShareGreenIcon from '../../../../assets/images/pages/share-icon-green.svg';
import LoadingIcon from '../../../../assets/images/loading.svg';
import { iOpportunities } from '../../../../interfaces/opportunities';
import { IProfessions } from '../../../../interfaces/professions';
import InfiniteScroll from 'react-infinite-scroll-component';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { jobsTypes } from '../../../../helpers/common';


interface iProps {
    opportunities: iOpportunities[];
    professions: IProfessions[];
    searchText: null | string;
    filters: null | {};
    resetTags: (index: number) => void;
}

export default class Results extends React.Component<iProps> {
    state = {
        items: this.props.opportunities.length > 0 ? this.props.opportunities.slice(0, 9) : [],
        showToast: false
    };
    private arrayKeys = jobsTypes.concat(this.props.professions.map((item: IProfessions) =>  {
        return {
            value: item.id,
            label: item.label
        }
    }));

    componentDidMount(): void { }
    componentDidUpdate(prevProps: Readonly<iProps>, prevState: Readonly<{}>, snapshot?: any): void {
        if (this.props.opportunities.length !== prevProps.opportunities.length) {
            this.setState({ items: this.props.opportunities.slice(0, 9) });
        }
    }

    LoadingComponent = () => {
        return (
            <div className="loader"><img src={LoadingIcon} alt="Cargando resultados..." /></div>
        )
    }

    resetTag = (index: number) => {
        this.props.resetTags(index + 1);
    }

    RenderFilter = (key: any, index: number) => {
        let findKey = this.arrayKeys.find((item: any) => item.value === key)
        let label = findKey && findKey.label ? findKey.label : key;
        return (
            <div key={index} className='tag-item'>
                <span>{label}</span>
                <span className='close' onClick={this.resetTag.bind(this, index)}>&times;</span>
            </div>
        )
    }

    RenderItem = (props: { item: iOpportunities, professions: IProfessions[] }) => {

        const hrefPath = `https://apply.worcket.com/?id=${props.item.id}`;
        const professions = props.professions.filter(item => props.item.professions.includes(item.id));

        return (
            <div className="col col-md-4 col-12">
                <div className="results-card">
                    <a href={hrefPath} target="_blank" rel='noreferrer'>
                        <div className="results-card-header">
                            <h3 className="results-title" title={props.item.title}>{props.item.title.length + 3 > 50 ? `${props.item.title.substring(0, 50)}...` : props.item.title}</h3>
                            {
                                professions.map((item, index) => {
                                    return <div className="results-tag" key={index}>{item.label}</div>
                                })
                            }
                        </div>
                        <div className="results-card-body">
                            <img src={props.item.image ?? props.item.companyLogo} alt="" />
                            <div className="results-card-action">
                                <OverlayTrigger
                                    overlay={(props) => (<Tooltip {...props}>Postularme</Tooltip>)}
                                    placement="top"
                                ><span style={{ cursor: 'pointer' }}><img src={AssessmentIconGren} alt="" /></span>
                                </OverlayTrigger>
                                <OverlayTrigger
                                    overlay={(props) => (<Tooltip {...props}>Compartir</Tooltip>)}
                                    placement="top"
                                ><span style={{ cursor: 'pointer' }} onClick={(e) => this.shareUrl(e, hrefPath)}><img src={ShareGreenIcon} alt="" /></span>
                                </OverlayTrigger>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        )
    }

    // fetchMoreData = () => {
    //     // a fake async api call like which sends
    //     // 9 more records in .5 secs
    //     setTimeout(() => {
    //         this.setState({
    //             items: this.state.items.concat(this.props.opportunities.slice(this.state.items.length, this.state.items.length + 9))
    //         });
    //     }, 500);
    // };

    fetchMoreData = () => {
        this.setState({
            items: this.state.items.concat(this.props.opportunities.slice(this.state.items.length, this.state.items.length + 9))
        });
    };

    shareUrl = (e: any, url: string) => {
        e.preventDefault();
        e.stopPropagation();
        navigator.clipboard.writeText(url);
        this.setState({ showToast: true });
        setTimeout(() => {
            this.setState({ showToast: false });
        }, 2500);
    }

    hasMoreData = () => this.state.items.length < this.props.opportunities.length;

    render() {
        return (
            <>
                <section className="results-section">
                    <div id="results">
                        <div className="container">
                            <div className="row">
                                <div className="col col-lg-10 offset-lg-1 col-md-12">
                                    <div>
                                        {
                                            this.props.searchText ? <h2 className="mp-hero-title">Resultados para: {this.props.searchText}</h2> : ''
                                        }
                                    </div>
                                    <div className='mb-4'>
                                        {
                                            this.props.filters ?
                                                Object.values(this.props.filters).map((key: any, i) => { return key != null ? this.RenderFilter(key, i) : '' }) : ''
                                        }
                                    </div>


                                    <InfiniteScroll
                                        dataLength={this.state.items.length} //This is important field to render the next data
                                        next={this.fetchMoreData}
                                        hasMore={this.hasMoreData()}
                                        loader={<this.LoadingComponent />}
                                        className="row"
                                        style={{ overflow: 'unset' }}
                                    >
                                        {this.state.items.map((item, index) => {
                                            return <this.RenderItem item={item} key={index} professions={this.props.professions} />
                                        })}
                                    </InfiniteScroll>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <ToastContainer style={{ position: 'fixed', bottom: '25px', left: '10px', zIndex: 9999 }}>
                    <Toast show={this.state.showToast}>
                        <Toast.Header onClick={() => this.setState({ showToast: false })}>
                            <FontAwesomeIcon style={{ color: 'blue' }} icon={faInfoCircle} />
                            <strong className="me-auto" style={{ marginLeft: '5px' }}>Se copió el link al Portapapeles</strong>
                        </Toast.Header>
                    </Toast>
                </ToastContainer>
            </>
        )
    }
}

export default class Paths {
    
    static Home = '/';

    static Experis = '/experis';

    static ManPower = '/manpower';

    static ManPowerBusiness = '/manpower-business';

}
import { Dispatch } from "@reduxjs/toolkit";
import { signIn } from './'
import { iAccount } from "../../interfaces/account";

export const getUserByStorage = () => (dispatch: Dispatch<any>) => {
    const data = localStorage.getItem('user');
    if (data) {
        const user = JSON.parse(data) as iAccount;
        return dispatch(signIn(user));
    }
    return dispatch(signIn(null));
}


import React from "react";
import GlobeIcon from "../../../../assets/images/pages/globe-icon.svg";
import Handshake from "../../../../assets/images/pages/handshake-icon.svg";
import ArrowUp from "../../../../assets/images/pages/arrow-up-icon.svg";

class BenefitsHome extends React.Component {
    render() {
        return (
            <section className="benefits">
                <div className="container">
                    <div className="row">
                        <div className="col col-12">
                            <h3>¿Por qué buscar trabajo a través de ManpowerGroup?</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col col-12">
                            <ul className="benefits-list">
                                <li className="benefits-item">
                                    <div className="media">
                                        <img src={GlobeIcon} alt="image_not_found"/>
                                    </div>
                                    <div className="content">
                                        <p>Cientos de oportunidades en un único lugar</p>
                                        <span>Podrás postularte a distintas oportunidades laborales de las empresas más importantes del mercado uruguayo.</span>
                                    </div>
                                </li>
                                <li className="benefits-item">
                                    <div className="media">
                                        <img src={Handshake} alt="image_not_found"/>
                                    </div>
                                    <div className="content">
                                        <p>Experiencia del candidato</p>
                                        <span>Tendrás acceso a los mejores consultores que te acompañarán en todo el recorrido de la búsqueda de empleo y te brindarán asesoramiento de carrera para mejorar tu empleabilidad.</span>
                                    </div>
                                </li>
                                <li className="benefits-item">
                                    <div className="media">
                                        <img src={ArrowUp} alt="image_not_found"/>
                                    </div>
                                    <div className="content">
                                        <p>Multiplica tus chances</p>
                                        <span>No hay un único empleo para todas tus habilidades. Contamos con diferentes unidades de especialización que cubren un amplio espectro de talentos y habilidades, desde perfiles operativos hasta jefaturas y cargos gerenciales.</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default BenefitsHome;
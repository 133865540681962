import React from "react";
import Slider, { Settings } from "react-slick"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { iJobPosts } from "../../../../interfaces/job-posts";

interface Props {
    posts: iJobPosts[];
}


class JobPostsHome extends React.Component<Props> {

    private slideConfig: Settings = {
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        dots: true,
        prevArrow: (<span className="slick-arrow left"><FontAwesomeIcon icon={faAngleLeft} /></span>),
        nextArrow: (<span className="slick-arrow right"><FontAwesomeIcon icon={faAngleRight} /></span>),
        responsive: [
            { breakpoint: 1200, settings: { slidesToShow: 3 } },
            { breakpoint: 992, settings: { slidesToShow: 2 } },
            { breakpoint: 768, settings: { slidesToShow: 1 } },
        ]
    }

    render() {
        const { posts } = this.props;

        return (
            <section id="jobPostsHome" className="job-posts">
                <div className="container">
                    <div className="row job-posts-title">
                        <div className="col col-12">
                            <h3>Sumate a ManpowerGroup</h3>
                            <div className="tag tag-orange">Queremos que formes parte de nuestro equipo y te animes a dejar tu huella con nosotros.</div>
                            <br />
                            <a href="https://www.youtube.com/watch?v=S8u7tdm2-4Q" target="_blank" rel="noopener noreferrer">¡Mirá cómo somos puertas adentro!</a>
                        </div>
                    </div>
                    {
                        posts.length > 0 ?
                            <Slider {...this.slideConfig} className="job-posts-carousel">
                                {
                                    posts.map((post, index) => (
                                        <div className="job-posts-card" key={index}>
                                            <a href={`https://apply.worcket.com/?id=${post.id}`}>
                                                <img src={post.image} alt={post.title} />
                                                <div className="job-position"><p>{post.title}</p></div>
                                            </a>
                                        </div>
                                    ))
                                }
                            </Slider> : ''
                    }
                </div>
            </section>
        )
    }
}

export default JobPostsHome;
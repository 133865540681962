import { SESClient, CreateTemplateCommand } from "@aws-sdk/client-ses";

/**
 * Esta clase se encarga de crear un template en AWS SES
 * Solo se debe ejecutar una vez para crear el template, luego se puede usar el nombre del template para enviar emails
 */
export default class CreateTemplate {

    static TEMPLATE_NAME = process.env.REACT_APP_EMAIL_TEMPLATE_NAME;
    static EMAIL_SUBJECT = process.env.REACT_APP_EMAIL_SUBJECT;
    static client: SESClient = new SESClient({
        region: process.env.REACT_APP_AWS_REGION,
        credentials: {
            accessKeyId: `${process.env.REACT_APP_AWS_ACCESS_KEY_ID}`,
            secretAccessKey: `${process.env.REACT_APP_AWS_SECRET_ACCESS_KEY}`
        }
    });


    static getTemplate() {
        return `
            <!DOCTYPE html>
            <html lang="es-ES" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:v="urn:schemas-microsoft-com:vml">
            <head>
            <title></title>
            <meta content="text/html; charset=utf-8" http-equiv="Content-Type"/>
            <meta content="width=device-width, initial-scale=1.0" name="viewport"/>
            <!--[if mso]><xml><o:OfficeDocumentSettings><o:PixelsPerInch>96</o:PixelsPerInch><o:AllowPNG/></o:OfficeDocumentSettings></xml><![endif]-->
            <style>
                @font-face {
                    font-family: 'Inter';
                    src: url('https://ejobs.manpower.com.uy/Inter-Regular.ttf') format('truetype');
                    font-weight: 400;
                    font-style: normal;
                }
                * { box-sizing: border-box; }
                body { margin: 0; padding: 0; }
                a[x-apple-data-detectors] { color: inherit !important; text-decoration: inherit !important; }
                #MessageViewBody a { color: inherit; text-decoration: none; }
                p { line-height: inherit }
                .desktop_hide,
                .desktop_hide table { mso-hide: all; display: none; max-height: 0px; overflow: hidden; }
                @media (max-width:700px) {
                    .row-4 .column-1 .block-3.image_block img { display: inline-block !important; }
                    .row-4 .column-1 .block-3.image_block .alignment { text-align: center !important; }
                }
                @media (max-width:620px) {
                    .row-content { width: 100% !important; }
                    .mobile_hide { display: none; }
                    .stack .column { width: 100%; display: block; }
                    .mobile_hide { min-height: 0; max-height: 0; max-width: 0; overflow: hidden; font-size: 0px; }
                    .desktop_hide,
                    .desktop_hide table { display: table !important; max-height: none !important; }
                    .row-4 .column-1 .block-2.heading_block h1 { text-align: center !important; }
                    .row-4 .column-1 .block-2.heading_block h1 { font-size: 18px !important; }
                    .row-6 .column-1 .block-2.paragraph_block td.pad { padding: 0 0 10px !important;}
                    .row-2 .column-1,
                    .row-2 .column-2 { padding: 0 !important; }
                    .row-4 .column-1 { padding: 0 24px !important; }
                }
            </style>
            </head>
            <body style="background-color: #f8fafc; margin: 0; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none;"><div class="preheader" style="display:none;font-size:1px;color:#333333;line-height:1px;max-height:0px;max-width:0px;opacity:0;overflow:hidden;">Inicio de sesión desde un nuevo dispositivo</div>
            <table border="0" cellpadding="0" cellspacing="0" class="nl-container" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #f8fafc;" width="100%">
            <tbody>
            <tr>
            <td>
            <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-1" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
            <tbody>
            <tr>
            <td>
            <table align="center" border="0" cellpadding="0" cellspacing="0" class="row-content stack" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; color: #000000; width: 600px;" width="600">
            <tbody>
            <tr>
            <td class="column column-1" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; padding-top: 0px; padding-bottom: 0px; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="100%">
            <div class="spacer_block" style="height:30px;line-height:30px;font-size:1px;"> </div>
            </td>
            </tr>
            </tbody>
            </table>
            </td>
            </tr>
            </tbody>
            </table>
            <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-2" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
            <tbody>
            <tr>
            <td>
            <table align="center" border="0" cellpadding="0" cellspacing="0" class="row-content stack" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #ffffff; border-radius: 4px; color: #000000; background-repeat: no-repeat; background-size: cover; width: 600px;" width="600">
            <tbody>
            <tr>
            <td class="column column-1" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-left: 30px; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="50%">
            <table border="0" cellpadding="0" cellspacing="0" class="image_block block-2" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
            <tr>
            <td class="pad" style="width:100%;padding-right:0px;padding-left:0px;padding-top:27px;padding-bottom:27px;">
            <div align="left" class="alignment" style="line-height:10px"><a href="https://ejobs.manpower.com.uy/" style="outline:none" tabindex="-1" target="_blank"><img alt="Manpower Logo" src="https://www.manpowergroup.com.uy/wps/wcm/connect/manpowergroup/2cac75e2-6851-4936-b8b3-c024a15134e9/manpowergroup-logo-200.png?MOD=AJPERES&CACHEID=ROOTWORKSPACE.Z18_2802IK01OORA70QUFIPQ192H31-2cac75e2-6851-4936-b8b3-c024a15134e9-mbVKPAs" style="display: block; height: auto; border: 0; width: 200px; max-width: 100%;" title="Manpower Logo" width="200"/></a></div>
            </td>
            </tr>
            </table>
            </td>
            <td class="column column-2" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-right: 30px; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="50%">
            <div class="spacer_block" style="height:0px;line-height:0px;font-size:1px;"> </div>
            <table border="0" cellpadding="0" cellspacing="0" class="button_block block-2 mobile_hide" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
            <tr>
            <td class="pad" style="text-align:right;">
            <div align="right" class="alignment">
            <!--[if mso]><v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" href="https://ejobs.manpower.com.uy/" style="height:86px;width:84px;v-text-anchor:middle;" arcsize="5%" stroke="false" fill="false"><w:anchorlock/><v:textbox inset="0px,0px,0px,0px"><center style="color:#436da7; font-family:Arial, sans-serif; font-size:14px"><![endif]--><a href="https://ejobs.manpower.com.uy/" style="text-decoration:none;display:inline-block;color:#436da7;background-color:transparent;border-radius:4px;width:auto;border-top:0px solid transparent;font-weight:700;border-right:0px solid transparent;border-bottom:0px solid transparent;border-left:0px solid transparent;padding-top:29px;padding-bottom:29px;font-family:Arial, Inter, sans-serif;text-align:center;mso-border-alt:none;word-break:keep-all;" target="_blank"><span style="padding-left:0px;padding-right:0px;font-size:14px;display:inline-block;letter-spacing:normal;"><span dir="ltr" style="word-break: break-word; line-height: 28px;">Ver Empleos</span></span></a>
            <!--[if mso]></center></v:textbox></v:roundrect><![endif]-->
            </div>
            </td>
            </tr>
            </table>
            <div class="spacer_block" style="height:0px;line-height:0px;font-size:1px;"> </div>
            </td>
            </tr>
            </tbody>
            </table>
            </td>
            </tr>
            </tbody>
            </table>
            <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-3" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
            <tbody>
            <tr>
            <td>
            <table align="center" border="0" cellpadding="0" cellspacing="0" class="row-content stack" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; color: #000000; width: 600px;" width="600">
            <tbody>
            <tr>
            <td class="column column-1" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; padding-top: 0px; padding-bottom: 0px; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="100%">
            <div class="spacer_block" style="height:10px;line-height:10px;font-size:1px;"></div>
            </td>
            </tr>
            </tbody>
            </table>
            </td>
            </tr>
            </tbody>
            </table>
            <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-4" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
            <tbody>
            <tr>
            <td>
            <table align="center" border="0" cellpadding="0" cellspacing="0" class="row-content stack" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #ffffff; border-radius: 4px; color: #000000; border-top: 3px solid #436DA7; border-right: 1px solid #EAEBEC; border-bottom: 1px solid #EAEBEC; border-left: 1px solid #EAEBEC; width: 600px;" width="600">
            <tbody>
            <tr>
            <td class="column column-1" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-left: 30px; padding-right: 30px; vertical-align: top; padding-top: 0px; padding-bottom: 0px; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="100%">
            <table border="0" cellpadding="0" cellspacing="0" class="heading_block block-2" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
            <tr>
            <td class="pad" style="width:100%;text-align:center;padding-bottom:20px;padding-top:35px;">
            <h1 style="margin: 0; color: #436da7; font-size: 24px; font-family: Arial, Inter, sans-serif; line-height: 120%; text-align: left; direction: ltr; font-weight: 700; letter-spacing: normal; margin-top: 0; margin-bottom: 0;"><span class="tinyMce-placeholder">Nuevo mensaje recibido</span></h1>
            </td>
            </tr>
            </table>
            <table border="0" cellpadding="0" cellspacing="0" class="paragraph_block block-3" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
            <tr>
            <td class="pad" style="padding-bottom:16px;">
            <div style="color:#343a40;font-size:14px;font-family:Arial, Inter, sans-serif;font-weight:400;line-height:150%;text-align:left;direction:ltr;letter-spacing:0px;mso-line-height-alt:21px;">
            <p style="margin: 0;">Hemos recibido un nuevo mensaje a través de la plataforma de empleos de <strong>ManpowerGroup</strong>.</p>
            </div>
            </td>
            </tr>
            </table>
            <table border="0" cellpadding="0" cellspacing="0" class="html_block block-4" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
            <tr>
            <td class="pad">
            <div align="center" style="font-family:Arial, Inter, sans-serif;text-align:center;"><div style="background-color: #f8f9fa;padding:20px 24px;">
            <p style="text-align:left;margin:0;line-height:1.5;font-size:14px;"><span style="font-weight:bold;">Página:</span> {{page}}
                <br/><span style="font-weight:bold;">Sector:</span> {{sector}}
                <br/><span style="font-weight:bold;">Nombre:</span> {{name}}
                <br/><span style="font-weight:bold;">Teléfono:</span> {{phone}}
                <br/><span style="font-weight:bold;">Email:</span> <a href="mailto:{{email}}" style="color:#c25700;text-decoration:none;">{{email}}</a>
                <br/><span style="font-weight:bold;">Asunto:</span> {{subject}}
                <br/><span style="font-weight:bold;">Mensaje:</span> {{message}}
            </p>
            </div></div>
            </td>
            </tr>
            </table>
            <table border="0" cellpadding="0" cellspacing="0" class="paragraph_block block-5" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
            <tr>
            <td class="pad" style="padding-top:16px;padding-bottom:35px;">
            <div style="color:#343a40;font-size:14px;font-family:Arial, Inter, sans-serif;font-weight:400;line-height:120%;text-align:left;direction:ltr;letter-spacing:0px;mso-line-height-alt:16.8px;">
            <p style="margin: 0; margin-bottom: 5px;">Muchas gracias,</p>
            <p style="margin: 0;"><strong>Manpower Group.</strong></p>
            </div>
            </td>
            </tr>
            </table>
            </td>
            </tr>
            </tbody>
            </table>
            </td>
            </tr>
            </tbody>
            </table>
            <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-5" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
            <tbody>
            <tr>
            <td>
            <table align="center" border="0" cellpadding="0" cellspacing="0" class="row-content stack" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; color: #000000; width: 600px;" width="600">
            <tbody>
            <tr>
            <td class="column column-1" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; padding-top: 0px; padding-bottom: 0px; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="100%">
            <div class="spacer_block" style="height:20px;line-height:20px;font-size:1px;"></div>
            </td>
            </tr>
            </tbody>
            </table>
            </td>
            </tr>
            </tbody>
            </table>
            <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-6" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
            <tbody>
            <tr>
            <td>
            <table align="center" border="0" cellpadding="0" cellspacing="0" class="row-content stack" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; color: #000000; border-radius: 0; width: 600px;" width="600">
            <tbody>
            <tr>
            <td class="column column-1" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; padding-top: 5px; padding-bottom: 5px; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="100%">
            <table border="0" cellpadding="0" cellspacing="0" class="paragraph_block block-2" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
            <tr>
            <td class="pad" style="padding-bottom:10px;padding-top:15px;">
            <div style="color:#6c757d;font-size:13px;font-family:Arial, Inter, sans-serif;font-weight:400;line-height:120%;text-align:center;direction:ltr;letter-spacing:0px;mso-line-height-alt:15.6px;">
            <p style="margin: 0; margin-bottom: 4px;">Enviado con ❤ por <a href="https://ejobs.manpower.com.uy/" rel="noopener" style="text-decoration: none; color: #c25700;" target="_blank"><strong>ManpowerGroup</strong></a>.</p>
            <p style="margin: 0; margin-bottom: 4px;">© Copyright Manpower Group. Todos los derechos reservados.</p>
            <p style="margin: 0;">Este e-mail es enviado desde una casilla automática, por favor no lo responda.</p>
            </div>
            </td>
            </tr>
            </table>
            </td>
            </tr>
            </tbody>
            </table>
            </td>
            </tr>
            </tbody>
            </table>
            <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-7" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
            <tbody>
            <tr>
            <td>
            <table align="center" border="0" cellpadding="0" cellspacing="0" class="row-content stack" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; color: #000000; width: 600px;" width="600">
            <tbody>
            <tr>
            <td class="column column-1" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; padding-top: 0px; padding-bottom: 0px; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="100%">
            <div class="spacer_block" style="height:20px;line-height:20px;font-size:1px;"> </div>
            </td>
            </tr>
            </tbody>
            </table>
            </td>
            </tr>
            </tbody>
            </table>
            </td>
            </tr>
            </tbody>
            </table><!-- End -->
            </body>
            </html>
        `;
    }


    static async run() {
        const emialTemplate = new CreateTemplateCommand({
            Template: {
                TemplateName: this.TEMPLATE_NAME,
                HtmlPart: this.getTemplate(),
                SubjectPart: this.EMAIL_SUBJECT,
            },
        });

        // Send to generate template
        try {
            await this.client.send(emialTemplate);
            return true;
        } catch (err) {
            console.error(err);
            throw new Error(`${err}`);
        }
    }

}
import { Dispatch } from "@reduxjs/toolkit";
import { _setProfessions, _setRoute } from "./";
import xhr from '../../services/api';

export const setRoute = (route: string) => (dispatch: Dispatch) => {
    dispatch(_setRoute(route));
}

export const setProfessions = () => async (dispatch: Dispatch) => {

    try{
        const professions = await xhr.getProfessions();
        dispatch(_setProfessions(professions));
    }catch(err){

    }

}
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-scroll';
import { jobsTypes } from '../../../../helpers/common'
import { IProfessions } from '../../../../interfaces/professions';
import { RootState } from '../../../../store';

interface states {
    menu1: string | null,
    menu2: string | null,
    menu3 : string | null,
}

interface props {
    dispatch: any,
    filtersData: any
    onFilter: (search: {}) => void;
    filter: states,
    professions: IProfessions[],
}

class Filter extends React.Component<props, states> {
    state: states = {
        menu1: this.props.filter.menu1,
        menu2: this.props.filter.menu2,
        menu3: this.props.filter.menu3,
    }

    componentDidMount() {
    }
    
    componentDidUpdate(prevProps: Readonly<props>): void {
        if (this.props.filter !== prevProps.filter) {
            this.setState({ menu1: this.props.filter.menu1, menu2: this.props.filter.menu2 , menu3: this.props.filter.menu3});
        }
    }


    handle = (event: any, number: string) => {
        switch (number) {
            case '1':
                this.setState({ menu1: event.target.value });
                break;
            case '2':
                this.setState({ menu2: event.target.value });
                break;
            case '3':
                this.setState({ menu3: event.target.value });
        }
    };


    onSubmit() {
        this.props.onFilter(this.state);
    }


    render() {


        return (
            <section className="filters-box">
                <div className="container">
                    <div className="row">
                        <div className="col col-xl-10 offset-xl-1 col-lg-12">
                            <div className="row filters-content">
                                <div className="col col-lg-3 col-md-6 col-12">
                                    <label htmlFor="search_keyword" className="form-label">Filtrar por trabajo</label>
                                    <select value={`${this.state.menu1}`} onChange={(e) => this.handle(e, '1')} className="form-select" aria-label="Default select example">
                                        <option hidden selected>Selecciona una opción</option>
                                        {
                                            jobsTypes.map((item, index) => {
                                                return <option key={index} value={item.value}>{item.label}</option>
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="col col-lg-3 col-md-6 col-12">
                                    <label htmlFor="search_keyword" className="form-label">Filtrar por ubicación</label>
                                    <select value={`${this.state.menu2}`} onChange={(e) => this.handle(e, '2')} className="form-select" aria-label="Default select example">
                                        <option hidden selected>Selecciona una opción</option>
                                        {
                                            this.props.filtersData.departments.map((item : any, index:number) => {
                                                return <option key={index} value={item}>{item}</option>
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="col col-lg-3 col-md-6 col-12">
                                    <label htmlFor="search_keyword" className="form-label">Áreas de especialización</label>
                                    <select value={`${this.state.menu3}`} onChange={(e) => this.handle(e, '3')} className="form-select" aria-label="Default select example">
                                        <option hidden selected>Selecciona una opción</option>
                                        {
                                            this.props.filtersData.professions.map((item : any, index:number) => {
                                                return <option key={index} value={item}>{this.props.professions.find(p => p.id === item)?.label }</option>
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="col col-lg-3 col-md-6 col-12">
                                    <Link to="results" offset={-200} spy={true} smooth={false}><button type="button" className="btn filters-btn" onClick={this.onSubmit.bind(this)}>Aplicar Filtros</button></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        professions : state.session.professions
    }
}

export default connect(mapStateToProps,{})(Filter)

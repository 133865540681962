import React from "react";
import ItCircle from "../../../../assets/images/pages/it_circle.svg"

class ContentExperis extends React.Component {

    render() {
        return (
            <section className="content-info">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col col-xl-5 col-lg-6 col-12">
                            <h3>Habilidades blandas en el mundo IT</h3>
                            <p>La empleabilidad no depende tanto de lo que una persona sabe, sino de lo que es capaz de aprender. Mirá cómo los gamers están desarrollando las habilidades que requieren los empleadores.</p>
                            <a href="https://www.youtube.com/watch?v=CoAWu5USunI" className="btn" target="_blank" rel="noopener noreferrer">Ver video</a>
                        </div>
                        <div className="col col-xl-6 offset-xl-1 col-lg-5 offset-lg-1 col-12 d-none d-lg-block">
                            <div className="img-container"><img src={ItCircle} alt="image_not_found"/></div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default ContentExperis;
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faTwitter, faYoutube, faLinkedinIn, faFacebookF } from '@fortawesome/free-brands-svg-icons'
import { RootState } from '../../store'
import { connect } from 'react-redux'
import { SessionRoute } from '../../interfaces/session'
import ExperisLogo from "../../assets/images/logos/experis_h.svg"
import ManpowerLogo from "../../assets/images/logos/mp-white.svg"
import ManpowerBusinessLogo from "../../assets/images/logos/manpower_bp_h_white.svg";

interface StateProps {
    route: SessionRoute
}

type Props = StateProps


class Footer extends React.Component<Props> {

    render() {

        return (
            <footer id='Footer' className="main">
                <div className="container">
                    <div className="row">
                        <div className="col col-md-5 col-12">
                            <div className="company-info">
                                {this.Logo()}
                                {this.Description()}
                            </div>
                        </div>
                        <div className="col col-md-3 col-12">
                            <div className="contact-address">
                                <p>Contacto</p>
                                <p>{this.Direction()}</p>
                                <p>2400 60 29</p>
                                <p>
                                    <a target="_blank" rel="noopener noreferrer" href='https://privacy-portal-manpowergroup.my.onetrust.com/webform/1ef6af7e-5375-472f-9219-c7092454fa4f/03dac1ef-0929-4341-81d6-da2df885cd42'>Privacidad de Datos</a>
                                </p>
                                <p>
                                    <a target="_blank" rel="noopener noreferrer" href='https://manpowergroup.com.uy/wps/portal/manpowergroup/uy/politica-y-terminos/politica-de-privacidad'>Políticas de Privacidad</a>
                                </p>
                            </div>
                        </div>
                        <div className="col col-md-4 col-12">
                            <div className="rrss">
                                <h5>¡Seguinos en nuestras Redes!</h5>
                                <ul className="rrss-icons">
                                    <li>
                                        <a href={this.Redes().linkedin} target="_blank" rel="noopener noreferrer">
                                            <FontAwesomeIcon  icon={faLinkedinIn} />
                                        </a>
                                    </li>
                                    <li>
                                        <a href={this.Redes().facebook} target="_blank" rel="noopener noreferrer">
                                            <FontAwesomeIcon  icon={faFacebookF} />
                                        </a>
                                    </li>
                                    <li>
                                        <a href={this.Redes().instagram} target="_blank" rel="noopener noreferrer">
                                            <FontAwesomeIcon  icon={faInstagram} />
                                        </a>
                                    </li>
                                    <li>
                                        <a href={this.Redes().twitter} target="_blank" rel="noopener noreferrer">
                                            <FontAwesomeIcon  icon={faTwitter} />
                                        </a>
                                    </li>
                                    <li>
                                        <a href={this.Redes().youtube} target="_blank" rel="noopener noreferrer">
                                            <FontAwesomeIcon  icon={faYoutube} />
                                        </a>
                                    </li>
                                </ul>
                                <a href={'https://'+this.ExtenalLink()} className="website">{this.ExternalLinkLabel()}</a>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col col-12">
                            <div className="copyright">&copy; 2022 ManpowerGroup. Todos los derechos reservados</div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }

    private Direction = () => {
        const { route } = this.props;
        switch (route) {
            case 'experis':
                return 'Sinergia Golf- Dr. Héctor Miranda 2361';
            case 'manpower-business':
            case 'manpower':
            default:
                return 'Acevedo Díaz 1513';

        }
    }

    private ExternalLinkLabel = () => {
        const { route } = this.props;
        switch (route) {
            case 'experis':
                return 'experis.manpowergroup.com.uy';
            case 'manpower-business':
                return 'manpowerbusinessprofessionals.manpowergroup.com.uy';
            case 'manpower':
                return 'www.manpower.com.uy';
            default:
                return 'www.manpowergroup.com.uy';
        }
    }

    
    private ExtenalLink = () => {
        const { route } = this.props;
        switch (route) {
            case 'experis':
                return 'experis.manpowergroup.com.uy/';
            case 'manpower-business':
                return 'manpowerbusinessprofessionals.manpowergroup.com.uy';
            case 'manpower':
                return 'www.manpower.com.uy';
            default:
                return 'www.manpowergroup.com.uy/wps/portal/manpowergroup/uy'

        }
    }
    private Redes = () : {linkedin:string,facebook:string,instagram:string,twitter:string,youtube:string} => {
        const { route } = this.props;
        switch (route) {
            case 'experis':
                return {
                    linkedin: 'https://www.linkedin.com/company/experis-uruguay/',
                    facebook: 'https://www.facebook.com/manpowergroupuruguay',
                    instagram: 'https://www.instagram.com/experis_uruguay/',
                    twitter: 'https://twitter.com/ExperisUru',
                    youtube: 'https://www.youtube.com/channel/UCXAWsOPrLn93RDRTzn8KeyQ',
                };
            case 'manpower-business':
            case 'manpower':
            default:
                return {
                    linkedin: 'https://www.linkedin.com/company/manpower-uruguay/',
                    facebook: 'https://www.facebook.com/manpowergroupuruguay/',
                    instagram: 'https://www.instagram.com/manpowergroupuy/',
                    twitter: 'https://twitter.com/ManpowerGroupUy',
                    youtube: 'https://www.youtube.com/user/manpoweruruguay',
                };

        }
    }

    private Logo (){
        const { route } = this.props;
        switch(route){
            case 'experis': return <img src={ExperisLogo} alt="Experis" />
            case 'manpower': return <img src={ManpowerLogo} alt="Manpower" />
            case 'manpower-business': return <img src={ManpowerBusinessLogo} alt="Manpower" />
            default: return null
        }
    }

    private Description() {
        const { route } = this.props;
        switch (route) {
            case 'experis': 
            return ( 
                <>
                    <h5>Acerca de Experis</h5>
                    <p>Ayudamos a las empresas durante todo el ciclo de adopción de tecnología, proporcionando el talento de IT y soluciones integrales que se adaptan a las necesidades de las organizaciones según sus etapas de desarrollo y madurez tecnológica.</p>
                </>
            )
            case 'manpower':
            return (
                <>
                    <h5>Acerca de Manpower</h5>
                    <p>Somos líderes globales en la provisión de personal eventual y contratación permanente. A través de nuestra experiencia en recursos de talento y gestión de la fuerza laboral, brindamos acceso rápido a un grupo de candidatos altamente calificados y productivos.</p>
                </>
            )
            case 'manpower-business':
                return (
                    <>
                        <h5>Acerca de Manpower</h5>
                        <p>Somos líderes en reclutamiento de talento de mandos medios y directivos. A través de nuestra experiencia brindamos acceso rápido a candidatos altamente calificados y con las habilidades que el mercado requiere.</p>
                    </>
                )
            default: 
            return (
                <>
                    <h5>Acerca de Manpowergroup</h5>
                    <p>Creemos en el desarrollo de las organizaciones y sus personas de forma sostenible. Contamos con más de 70 años liderando soluciones innovadoras de talento humano que cubren todo el ciclo del empleo. </p>
                </>
            )
        }
    }
}

const stateToProps = (state: RootState) => {
    return {
        route: state.session.route
    }
}

export default connect(stateToProps, {})(Footer);
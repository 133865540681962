import { createSlice, SliceCaseReducers } from "@reduxjs/toolkit";
import { iAccount } from "../../interfaces/account";
import ACCOUNT from "./store";


export const sAccount = createSlice<iAccount, SliceCaseReducers<iAccount>, 'account'>({
    name: 'account',
    initialState: ACCOUNT,
    reducers: {
        signIn: (store, action) => {
            store.user = action.payload;
            store.isAuth = store.user !== null ? true : false;
        }
    }
})

export default sAccount.reducer;

export const { signIn } = sAccount.actions
import React from "react";
import AssetBlog1 from "../../../../assets/images/pages/blog1.png";
import AssetBlog2 from "../../../../assets/images/pages/blog2.png";
import AssetBlog3 from "../../../../assets/images/pages/blog3.png";


export interface iCards {
    title: string;
    img: string;
    link: string
}

const CardsHome = (): iCards[] => {
    return [{
        title: 'CÓMO AGREGARLE VALOR A TU CV',
        img: AssetBlog1,
        link: 'https://blog.manpowergroup.com.uy/c%C3%B3mo-agregarle-valor-a-tu-cv ',
    }, {
        title: '¿QUÉ RESPONDER A LAS CUALIDADES Y OPORTUNIDADES DE MEJORA EN UNA ENTREVISTA DE TRABAJO?',
        img: AssetBlog2,
        link: 'https://blog.manpowergroup.com.uy/qu%C3%A9-responder-a-los-defectos-y-cualidades-en-una-entrevista-de-trabajo',
    }, {
        title: '5 CONSEJOS PARA SER MÁS EMPLEABLE',
        img: AssetBlog3,
        link: 'https://blog.manpowergroup.com.uy/5-consejos-para-ser-m%C3%A1s-empleable',

    }]
}


class BlogHome extends React.Component {

    state: { cards: any[] } = {
        cards: []
    }
    

    componentDidMount(): void {
        this.setState({ cards: CardsHome() })
    }

    _blank = (link: string) => {
        window.open(link, '_blank');
    }


    render() {
        return (
            <section className="blog">
                <div className="full-container">
                    <div className="container">
                        <div className="row">
                            <div className="col col-lg-12">
                                <h3>ManpowerGroup Blog</h3>
                                <p className="blog-subtitle">Conocé las últimas tendencias en desarrollo profesional y mejorá tu empleabilidad.</p>
                            </div>
                        </div>
                    </div>
                    <div className="blog-content">
                        {
                            this.state.cards.map((card: any, index: number) => {
                                return (
                                    <div className="blog-item" onClick={() => this._blank(card.link)} key={index}>
                                        <div className="blog-img" style={{ backgroundImage: `url(${card.img})` }}></div>
                                        <h4 className="blog-title">{card.title}</h4>
                                    </div>
                                )
                            })
                        }

                    </div>
                    <div className="more-blog">
                        <a href="https://blog.manpowergroup.com.uy/">Ver Más Artículos</a>
                    </div>
                </div>
            </section>
        )
    }
}

export default BlogHome;
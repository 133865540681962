import React from 'react';

class HeaderManpower extends React.Component {
  render() {
    return (
      <section className="mp-hero d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col col-lg-6 col-md-10 col-12">
              <div className="mp-hero-content">
                <h2 className="mp-hero-title">Cargos operativos y administrativos</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default HeaderManpower;

/** @format */

import React from "react";
import API from "../../services/api";
import { connect } from "react-redux";
import { iJobPosts } from "../../interfaces/job-posts";
import { iSession, SessionRoute } from "../../interfaces/session";
import { RootState } from "../../store";
import { setProfessions, setRoute } from "../../store/session/actions";
import AttributesHome from "./components/attributes";
import BenefitsHome from "./components/benefits";
import BlogHome from "./components/blog";
import CommentsHome from "./components/comments";
import CompaniesHome from "./components/companies";
import HeaderHome from "./components/header";
import JobPostsHome from "./components/job-posts";
import LogosHome from "./components/logos";
import Book from "../../assets/images/pages/book.jpg";
import Footer from "../../containers/footer";
import Header from "../../containers/header";

interface DispatchProps {
    setProfessions: () => void;
    setRoute: (route: SessionRoute) => void;
}

interface StoreProps {
    Session: iSession;
}

type Props = DispatchProps & StoreProps;

interface State {
    posts: iJobPosts[];
}

class HomeScreen extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            posts: [],
        };
    }

    componentDidMount(): void {
        document.title = "Manpower - Home";
        this.props.setRoute("main");
        this.props.setProfessions();
        if (this.state.posts == null || this.state.posts.length === 0) {
            this.setJobPosts();
        }
    }

    render() {
        return (
        <>
            <Header />
            <HeaderHome />
            <BenefitsHome />
            <AttributesHome />
            <CompaniesHome />
            <JobPostsHome posts={this.state.posts} />
            <section className='curve-ending green'>
                <svg
                    xmlns='http://www.w3.org/2000/svg'
                    preserveAspectRatio='none'
                    x='0px'
                    y='0px'
                    width='100%'
                    height='100px'
                    viewBox='0 0 1920 140'
                    style={{ marginBottom: "-9px" }}
                    xmlSpace='preserve'
                >
                    <path
                        style={{ fill: "#FFFFFF" }}
                        d='M960,92.9C811.4,93.3,662.8,89.4,515.3,79c-138.6-9.8-277.1-26.2-409-53.3C97.8,24,0,6.5,0,0c0,0,0,140,0,140  l960-1.2l960,1.2c0,0,0-140,0-140c0,2.7-42.1,11.3-45.8,12.2c-45.1,11-91.5,20.1-138.4,28.1c-176.2,30.1-359.9,43.8-542.9,48.9  C1115.4,91.4,1037.7,92.7,960,92.9z'
                    />
                </svg>
            </section>
            <CommentsHome />
            <BlogHome />
            <section className='download-book'>
                <div className='container mb-5'>
                    <div className='row justify-content-center align-items-center'>
                        <div className='mb-4 mb-md-0 col-sm-6 col-lg-3 col-xl-2'>
                            <div className='book-img'>
                                <img src={Book} alt='En sus marcas, listos… ¡empleate!' />
                            </div>
                        </div>
                        <div className='col-md-6 col-lg-5 col-xl-4'>
                            <div className='book-content'>
                                <h4>
                                    <strong>Descargá gratis el libro </strong>
                                    <i>En sus marcas, listos… ¡empleate!</i>
                                </h4>
                                <p>
                                    de Mónica Flores, Directora de ManpowerGroup LATAM, sobre CV, Entrevistas
                                    laborales, Linkedin y más.
                                </p>
                                <a
                                    className='btn hero-btn'
                                    href='https://news.manpowergroup.com.mx/libro/empleate'
                                    target='_blank'
                                    rel='noreferrer'
                                >
                                    Descargar Libro
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='page-up'>
                <div className='container'>
                    <div className='row'>
                        <div className='col col-lg-12'>
                            <button onClick={this.GoTop.bind(this)} className='btn'>
                                Volver Arriba
                            </button>
                        </div>
                    </div>
                </div>
            </section>
            <LogosHome />
            <Footer />
        </>
        );
    }

    private GoTop() {
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
    }

    private setJobPosts() {
        API.getHome()
            .then((posts) => this.setState({ posts }))
            .catch((err) => console.error("Error get data home", err));
    }
}

const stateToProps = (state: RootState) => {
    return {
        Session: state.session,
    };
};

const dispatchToProps = { setProfessions, setRoute };

export default connect(stateToProps, dispatchToProps)(HomeScreen);
import React from "react";
import LogoManpowerGPH from "../../../../assets/images/logos/manpower_gp_h.svg";
import LogoManpowerH from "../../../../assets/images/logos/manpower_h.svg";
import LogoTalentGr from "../../../../assets/images/logos/TS_Logo_RGB_HOR.svg"
import LogoExperisH from '../../../../assets/images/logos/experis_h.svg';


class LogosHome extends React.Component {

    render() {
        return (
            <section className="logos">
                <div className="container">
                    <div className="row">
                        <div className="col col-12 col-md-3 col-sm-6">
                            <a target="_blank" rel="noopener noreferrer" href="https://manpowergroup.com.uy/wps/portal/manpowergroup/uy">
                                <img src={LogoManpowerGPH} alt="ManpowerGroup"/>
                            </a>
                        </div>
                        <div className="col col-12 col-md-3 col-sm-6">
                            <a target="_blank" rel="noopener noreferrer" href="https://www.manpower.com.uy/">
                                <img src={LogoManpowerH} alt="ManpowerGroup"/>
                            </a>
                        </div>
                        <div className="col col-12 col-md-3 col-sm-6">
                            <a target="_blank" rel="noopener noreferrer" href="https://experis.manpowergroup.com.uy/">
                                <img src={LogoExperisH} alt="Experis" />
                            </a>
                        </div>
                        <div className="col col-12 col-md-3 col-sm-6">
                            <a target="_blank" rel="noopener noreferrer" href="https://www.rightmanagement.com.uy/">
                                <img src={LogoTalentGr} alt="ManpowerGroup"/>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default LogosHome;
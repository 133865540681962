import React from 'react'
import Woman from '../../../../assets/images/pages/Video-Manpower.jpg'

const ContentInfo = () => {
    return (
        <>
            <section className="content-info">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col col-xl-5 col-lg-6 col-12">
                            <h3>¿Cómo armar tu CV?</h3>
                            <p>Aprendé cómo hacerlo de forma correcta con nuestra charla de CV Checking a cargo de integrantes de nuestro equipo de evaluadores.</p>
                            <a href="https://www.youtube.com/watch?v=E6TmbjAlDcA&t=479s" className="btn" target="_blank" rel="noopener noreferrer">Ver Charla</a>
                        </div>
                        <div className="col col-xl-6 offset-xl-1 col-lg-5 offset-lg-1 col-12 d-none d-lg-block">
                            <div className="img-container"><img src={Woman} alt="image_not_found"/></div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ContentInfo
import React from 'react'

export default class CurriculumVitae extends React.Component {
    render() {
        return (
            <>
                <section className="send-cv">
                    <div className="container">
                        <div className="row">
                            <div className="col col-lg-12">
                                <h3>¿No encontraste oportunidades que coincidan con tu perfil?</h3>
                                <a href="https://apply.worcket.com/?id=6c082797-4454-4a1d-a2a3-1e0ffae50209 " target="_blank " className="btn" rel="noopener noreferrer">Cargá tu CV</a>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

import { SESClient, SendTemplatedEmailCommand } from "@aws-sdk/client-ses";

export interface IBody {
    page        : 'Home' | 'Manpower' | 'Manpower BP' | 'Experis',
    sector      : string;
    name        : string;
    phone       : string;
    email       : string;
    subject     : string;
    message     : string;
}


export default class SendEmail {

    static client: SESClient = new SESClient({ 
        region: process.env.REACT_APP_AWS_REGION, 
        credentials: {
            accessKeyId: `${process.env.REACT_APP_AWS_ACCESS_KEY_ID}`,
            secretAccessKey: `${process.env.REACT_APP_AWS_SECRET_ACCESS_KEY}`
        } 
    });

    private static createSendEmailWithTemplate = (toAddress: string, fromAddress: string, templateName: string, params: any) => {
        return new SendTemplatedEmailCommand({
            Destination: { ToAddresses: [toAddress] },
            TemplateData: JSON.stringify(params),
            Source: fromAddress,
            Template: templateName,
        });
    }

    static sendContact = async (data: IBody) => {

        let emailTo
        switch (data.sector) {
            case 'Candidato':
                emailTo = process.env.REACT_APP_MAIL_TO_CANDIDATE;
                break;
            case 'Empresas':
                emailTo = process.env.REACT_APP_MAIL_TO_BUSINESS;
                break;
            case 'Support':
                emailTo = process.env.REACT_APP_MAIL_TO_SUPPORT;
                break;
            default:
                break;
        }
        const emailCommand = this.createSendEmailWithTemplate(
            `${emailTo}`,
            `${process.env.REACT_APP_MAIL_FROM}`,
            `${process.env.REACT_APP_EMAIL_TEMPLATE_NAME}`,
            {
                page    : data.page,
                sector  : data.sector,
                name    : data.name,
                phone   : data.phone,
                email   : data.email,
                subject : data.subject,
                message : data.message,
            }
        );
        
        try {
            return await this.client.send(emailCommand);
        } catch (e) {
            console.error("Failed to send email.");
            return e;
        }

    }
}
import React from 'react'
import ManPowerGroup from '../../assets/images/logos/manpower_gp_h.svg'
import ManPower from '../../assets/images/logos/manpower_h.svg'
import ManPowerBusiness from '../../assets/images/logos/manpower_bp_h.svg'
import Experis from '../../assets/images/logos/experis_h_white.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons'
import { RootState } from '../../store'
import { connect } from 'react-redux'
import { iSession } from '../../interfaces/session'
import ContactModal from '../contactModal'

interface StateProps {
    Session: iSession;
}


type Props = StateProps

interface States {
    isActive: boolean,
    isShowModal: boolean
}

class Header extends React.Component<Props, States> {
    

    state = {
        isActive: false,
        isShowModal: false
    }

    render() {
        const { Session } = this.props
        const { isActive, isShowModal} = this.state

        let logo = ManPowerGroup;
        let pageName: any = 'Home';
        switch(Session.route) {
            case 'experis':
                logo = Experis;
                pageName = 'Experis';
                break;
            case 'manpower-business':
                logo = ManPowerBusiness;
                pageName = 'Manpower BP';
                break;
            case 'manpower':
                logo = ManPower;
                pageName = 'Manpower';
                break;
        }

        return (
            <>
                <ContactModal isShowModal={isShowModal} pageName={ pageName } onCloseModal={() => this.setState({isShowModal:!isShowModal})}/>
                <header className={`d-flex align-items-center ${isActive ? 'mobile-active' : ''}`}>
                    <div className="full-container">
                        <div className="d-flex justify-content-between">
                            <div className="brand">
                                <a href="/" className="brand-link"><img src={logo} alt="Manpower Logo" className="brand-logo" /></a>
                            </div>
                            <nav className="nav">
                                <ul className="nav-bar d-flex align-items-center">
                                    <li className="nav-item d-none d-md-block"><a href="https://candidatos.worcket.com/" target="_blank" rel='noreferrer' className="nav-link">Iniciar Sesión</a></li>
                                    {/* only with url '/' */}{
                                        window.location.pathname === '/' ? <li className="nav-item d-none d-md-block"><a href="https://apply.worcket.com/?id=6c082797-4454-4a1d-a2a3-1e0ffae50209" target="_blank" rel='noreferrer' className="nav-link">Crear Usuario</a></li> : ''
                                    }
                                    <li className="nav-item d-none d-md-block"><div className="nav-link" onClick={this.handleToggleModal.bind(this)}>Contacto</div></li>
                                    <li className="nav-item d-block d-md-none">
                                        <div className="nav-link mobile-btn">
                                            {
                                                isActive ? <FontAwesomeIcon icon={faXmark} onClick={this.handleToggle.bind(this)} /> : <FontAwesomeIcon icon={faBars} onClick={this.handleToggle.bind(this)} />
                                            }
                                        </div>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                    <nav className="d-block d-md-none mobile-nav">
                        <ul className="mobile-nav-bar">
                            <li className="mobile-nav-item"><a href="https://candidatos.worcket.com/" target="_blank" rel='noreferrer' className="mobile-nav-link">Iniciar Sesión</a></li>
                            {/* only with url '/' */}{
                                window.location.pathname === '/' ? <li className="mobile-nav-item"><a href="https://apply.worcket.com/?id=6c082797-4454-4a1d-a2a3-1e0ffae50209" target="_blank" rel='noreferrer' className="mobile-nav-link">Crear Usuario</a></li> : ''
                            }
                            <li className="mobile-nav-item"><div className="mobile-nav-link" onClick={this.handleToggleModal.bind(this)}>Contacto</div></li>
                        </ul>
                    </nav>
                </header>
            </>
        )
    }

    private handleToggle = () => {
        this.setState({ isActive: !this.state.isActive })
    };
    private handleToggleModal = () => {
        this.setState({ isShowModal: !this.state.isShowModal })
    };

}

const stateToProps = (state: RootState) => {
    return {
        Session: state.session
    }
}

const dispatchToProps = {}

export default connect(stateToProps, dispatchToProps)(Header);
import React from "react";
import { connect } from "react-redux";
import { iSession, SessionRoute } from "../../interfaces/session";
import { RootState } from "../../store";
import { setProfessions, setRoute } from "../../store/session/actions";
import HeaderBusiness from "./components/header";
import Filter from "./components/filter";
import SearchBox from "./components/searchBox";
import Info from "./components/info";
import Results from "./components/results";
import CurriculumVitae from "./components/curriculum";
import API from '../../services/api';
import { iOpportunities } from '../../interfaces/opportunities';
import { getDepartments } from "../../helpers/common";
import Header from "../../containers/header";
import Footer from "../../containers/footer";

interface DispatchProps {
    setProfessions: () => void;
    setRoute: (route: SessionRoute) => void;
}

interface StoreProps {
    Session: iSession;
}

type Props = DispatchProps & StoreProps


interface iStates {
    data: null | iOpportunities[];
    auxData: null | iOpportunities[];
    filtersData: null | any;
    searchText: null | string,
    filters: iFilters,
}
interface iFilters {
    menu1: string | null,
    menu2: string | null,
    menu3: string | null,
}


class ManPowerBusinessScreen extends React.Component<Props, iStates> {

    state: iStates = {
        data: null,
        auxData: null,
        searchText: null,
        filtersData: {departments : [] , professions : []},
        filters: {
            menu1: null,
            menu2: null,
            menu3: null,
        }
    };

    componentDidMount(): void {
        document.title = 'Mandos medios y cargos gerenciales - Manpower Business Professionals';
        this.props.setRoute('manpower-business');
        this.props.setProfessions();
        if (this.state.data == null) {
            this.getData();
        }
    }

    getData = () => {
        API.getMampoweBusiness().then((data) => {
            this.setState({
                data, auxData: data, filtersData: {
                    departments: getDepartments(data, 'departments'),
                    professions: getDepartments(data, 'professions'),
                }
            });
            
        });
    }

    search(params: any) {
        const newRegex = new RegExp(params, 'i');
        const tmpData = this.state.data?.filter(item => item.title.match(newRegex)) || [];
        this.setState({ auxData: tmpData });
        this.setState({ searchText: params });
    }

    filter(params: any) {
        this.setState({ filters: params });
        if (this.state.data == null) return
        const tmpData = this.state.data.filter((item) => {
            let flag = true;
            Object.values(params).forEach((value : any ,index:number) => {
                if(value === null) return
                else if(index === 0 && item.jobType !== value) return flag = false
                else if(index === 1 && !item.geolocations.some(geo => geo.display_name.includes(value) )) return flag = false
                else if(index === 2 && !item.professions.some(prof => prof === value)) return flag = false
            })
            return flag ? item : false
        });
        this.setState({ auxData: tmpData });
    }


    resetTag = (index: number) => {
        this.setState({ auxData: this.state.data });
        this.setState({
            filters: {
                ...this.state.filters,
                [`menu${index}`]: null,
            }
        })
    }

    render() {
        return (
            <>
                <Header />
                <HeaderBusiness />
                <SearchBox search={this.search.bind(this)} />
                <Filter onFilter={this.filter.bind(this)} filter={this.state.filters} filtersData={this.state.filtersData} dispatch/>
                <Info />
                {
                    this.state.auxData != null && <Results resetTags={this.resetTag.bind(this)} opportunities={this.state.auxData} professions={this.props.Session.professions} searchText={this.state.searchText} filters={this.state.filters} />
                }
                <CurriculumVitae />
                <Footer />
            </>
        )
    }
}

const stateToProps = (state: RootState) => {
    return {
        Session: state.session
    }
}

const dispatchToProps = { setProfessions, setRoute };

export default connect(stateToProps, dispatchToProps)(ManPowerBusinessScreen);
import React from "react";
import { connect } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { RootState } from "../store";
import { getUserByStorage } from "../store/account/actions";
import Paths from "../services/paths";
import HomeScreen from "./home";
import ExperisScreen from "./experis";
import ManPowerScreen from "./manpower";
import ManPowerBusinessScreen from "./business";

interface State { 
    isAuth: boolean;
}

interface Dispatch {
    getUserByStorage: () => void;
}

type Props = State & Dispatch;

class Screens extends React.Component<Props> {

    constructor(props: Props) {
        super(props);
        props.getUserByStorage();
    }

    render() {
        return (
            <Routes>
                <Route path={Paths.Home} element={<HomeScreen />} />
                <Route path={Paths.Experis} element={<ExperisScreen />} />
                <Route path={Paths.ManPower} element={<ManPowerScreen />} />
                <Route path={Paths.ManPowerBusiness} element={<ManPowerBusinessScreen />} />
                <Route path="*" element={<Navigate to={Paths.Home} />} />
            </Routes>
        )
    }
}

const stateToProps = (state: RootState) => {
    return {
        isAuth: state.account.isAuth
    }
}


const dispatchToProps = { getUserByStorage }

export default connect(stateToProps, dispatchToProps)(Screens);
import { configureStore } from "@reduxjs/toolkit";
import session from './session';
import account from './account';

/** Creamos el `Store` */
const store = configureStore({
    reducer: { session, account }
})

/** Tipo de `Store` */
export type RootState = ReturnType<typeof store.getState>
/** Tipo de `Dispatch` */ 
export type AppDispatch = typeof store.dispatch

/** Exportamos el `Store` */
export default store;
import React from "react";
import PeopleIcon from "../../../../assets/images/pages/people-icon.svg";
import InnovationIcon from "../../../../assets/images/pages/innovation-icon.svg";
import KnowledgeIcon from "../../../../assets/images/pages/knowledge-icon.svg";

class AttributesHome extends React.Component {

    render() {
        return (
            <section className="attributes">
                <div className="full-container">
                    <div className="row">
                        <div className="col col-12 col-md-4">
                            <div className="attributes-item">
                                <img src={PeopleIcon} alt="Valoramos a las Personas" />
                                <h4>Valoramos a las <span className="fw-bolder">Personas</span></h4>
                                <p>Sabemos el papel que representa el empleo en sus vidas</p>
                            </div>
                        </div>
                        <div className="col col-12 col-md-4">
                            <div className="attributes-item">
                                <img src={InnovationIcon} alt="Valoramos a las Personas" />
                                <h4>Impulsamos la <span className="fw-bolder">Innovación</span></h4>
                                <p>Siempre buscamos una forma mejor de hacer las cosas</p>
                            </div>
                        </div>
                        <div className="col col-12 col-md-4">
                            <div className="attributes-item">
                                <img src={KnowledgeIcon} alt="Valoramos a las Personas" />
                                <h4>Lideramos con <span className="fw-bolder">Conocimiento</span></h4>
                                <p>Somos conscientes de la evolución que vive nuestra industria</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default AttributesHome;
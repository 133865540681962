export const commentsJson : Comment[] = [
    {
        "id": "1",
        "name": "Gime",
        "comment": "Me motivan las posibilidades de crecimiento que ofrece la empresa, diseñando el plan de carrera alineado a nuestras fortalezas y capacidades. También valoro la apertura constante por parte de los líderes y destaco el clima laboral, el cual es excelente.",
        "img": "/images/commentsImages/Foto-Gime.jpg"
    },
    {
        "id": "2",
        "name": "Ine",
        "comment": "Siento orgullo de formar parte de una organización que impulsa el desarrollo profesional y personal. En ManpowerGroup se percibe un excelente clima laboral fomentado por el equipo y principalmente sostenido por las actividades impulsadas por People & Culture.",
        "img": "/images/commentsImages/Foto-Ine.jpeg"
    },
    {
        "id": "3",
        "name": "Marcio",
        "comment": "Hay un muy buen equipo y gran compañerismo. Se presentan muchos desafíos en el día a día, pero esto no frena la posibilidad de disfrutar mientras se trabaja. La flexibilidad del modelo híbrido es ideal para poder tener un equilibrio entre la vida laboral y personal.",
        "img": "/images/commentsImages/Foto-Marcio.png"
    },
    {
        "id":"4",
        "name": "Vale",
        "comment": "Me encanta el equipo que conforma ManpowerGroup. Es una empresa que estimula la colaboración entre las áreas. Tenemos flexibilidad para estudiar, espacios y actividades para el autoconocimiento.",
        "img": "/images/commentsImages/Foto-Vale.jfif"
    },
    {
        "id":"5",
        "name": "Nico",
        "comment": "Hace 6 años comencé a trabajar en Manpower, me encontré con una gran empresa que me acompañó a desarrollarme tanto personal como profesionalmente. Destaco la sinergia del equipo de trabajo y a los líderes que te acompañan día a día para lograr sacar tu mejor potencial.",
        "img": "/images/commentsImages/Foto-Nico.jpg"
    },
    {
        "id":"6",
        "name": "Mati",
        "comment": "Si trabajás en Manpower vas a aprender muchísimo, vas a disfrutar de un equipo en el que todos tiran hacia el mismo lado, vas a descubrir habilidades que no sabías que tenías.",
        "img": "/images/commentsImages/Foto-Mati.jpg"
    },
    {
        "id":"7",
        "name": "Vero",
        "comment": "Es una empresa que se preocupa por el bienestar de sus colaboradores y siempre está pensando en acciones, beneficios, etc. para lograr que todos tengamos un mejor equilibrio entre lo profesional y personal.",
        "img": "/images/commentsImages/Foto-Vero.jfif"
    }
]

interface Comment {
    id: string;
    name: string;
    comment: string;
    img: string;
}
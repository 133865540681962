import { Subject } from "rxjs";
// import Form from "..";
import { AsyncValidatorFn, ValidatorError, ValidatorsFn } from "./validators";

interface onControllers<TValue = any> {
    setValue(value: TValue, options?: FormSetOptions): void;
    applyValidators(options?: FormSetOptions): void;
    reset(defaultValue: TValue, options: FormSetOptions): void;
}

interface FormSetOptions {
    emitChange: boolean;
    emitStatus: boolean;
}

const DEFAULT_SET_OPTIONS: FormSetOptions = {
    emitChange: true,
    emitStatus: true
}

export abstract class Controller<Tvalue = any> {

    public name!: string;

    public value!: Tvalue;

    public validators!: ValidatorsFn[];

    public asyncValidators!: AsyncValidatorFn[];

    public status: 'valid' | 'invalid' = 'valid';

    public isValid!: boolean;

    public isInvalid!: boolean;

    public isDisabled: boolean = false;

    public isTouched: boolean = false;

    public isDirty: boolean = false;

    readonly onChange = new Subject<Tvalue>();

    readonly onStatusChange = new Subject<'valid' | 'invalid'>();

    public setValidators(validators: ValidatorsFn | ValidatorsFn[]): void {
        this.validators = typeof validators === 'function' ? [validators] : validators;
    }

    public setAsyncValidators(asyncValidators: AsyncValidatorFn | AsyncValidatorFn[]): void {
        this.asyncValidators = typeof asyncValidators === 'function' ? [asyncValidators] : asyncValidators;
    }

    public addValidators(validators: ValidatorsFn | ValidatorsFn[]): void {
        if (!this.validators) this.validators = [];
        if (typeof validators === 'function') {
            if (this.validators.includes(validators)) return;
            this.validators = [...this.validators, validators];
        } else {
            for (const validator of validators) {
                if (this.validators.includes(validator)) continue;
                this.validators = [...this.validators, validator];
            }
        }

    }

    public addAsyncValidators(asyncValidators: AsyncValidatorFn | AsyncValidatorFn[]): void {
        if (!this.asyncValidators) this.asyncValidators = [];
        if (typeof asyncValidators === 'function') {
            if (this.asyncValidators.includes(asyncValidators)) return;
            this.asyncValidators = [...this.asyncValidators, asyncValidators];
        } else {
            for (const asyncValidator of asyncValidators) {
                if (this.asyncValidators.includes(asyncValidator)) continue;
                this.asyncValidators = [...this.asyncValidators, asyncValidator];
            }
        }
    }

    public removeValidator(validator: ValidatorsFn): void {
        this.validators = this.validators.filter(v => v !== validator);
    }

    public removeAsyncValidator(asyncValidator: AsyncValidatorFn): void {
        this.asyncValidators = this.asyncValidators.filter(v => v !== asyncValidator);
    }

    public clearValidators() { this.validators = [] };

    public clearAsyncValidators() { this.asyncValidators = [] };

    public disable() { this.isDisabled = true };

    public enable() { this.isDisabled = false };

    public markDirty() { this.isDirty = true };

    public markClean() { this.isDirty = false };

    public markTouched() { this.isTouched = true };

    public markUntouched() { this.isTouched = false };
}

export class FormControl<TValue = any> extends Controller<TValue> implements onControllers<TValue> {

    public name: string = 'formControl';

    private defaultValue!: TValue;

    public parent!: FormGroup | null;

    public errors: (ValidatorError | null) = null;

    constructor(
        public value: TValue,
        validators?: ValidatorsFn | ValidatorsFn[],
        asyncValidators?: AsyncValidatorFn | AsyncValidatorFn[]
    ) {
        super();
        if (validators) this.setValidators(validators);
        if (asyncValidators) this.setAsyncValidators(asyncValidators);
        this.defaultValue = value;
        this.value = this.defaultValue;
        this.applyValidators();
    }

    public setValue(value: TValue, options: FormSetOptions = DEFAULT_SET_OPTIONS): void {
        if (this.value === value) return;
        this.value = value;
        this.applyValidators(options);
        if (options.emitChange) {
            this.onChange.next(value);
            this.defaultValue !== value ? this.markDirty() : this.markClean();
            this.markTouched();
        }
    }

    public applyValidators(options: FormSetOptions = DEFAULT_SET_OPTIONS): void {
        if (this.validators) {
            const sequences = this.validators.map(v => v(this.value));

            const oldStatus = this.status;
            this.isValid = sequences.every(validator => validator === null);
            this.isInvalid = !this.isValid;
            this.status = this.isValid ? 'valid' : 'invalid';
            if (this.status !== oldStatus && options.emitStatus) this.onStatusChange.next(this.status);

            let errors = sequences.filter(validator => validator !== null);
            if (errors.length) this.errors = errors.reduce((acc, cur) => ({ ...acc, ...cur }), {});
        }
    }

    public reset(defaultValue?: TValue, options: FormSetOptions = DEFAULT_SET_OPTIONS): void {
        if (defaultValue) this.defaultValue = defaultValue;
        this.setValue(this.defaultValue, options);
    }
}

export class FormGroup<TControl = any> extends Controller<TControl> implements onControllers<TControl> {

    public name: string = 'formGroup';

    private defaultValue!: TControl;

    public controls: { [K in keyof TControl]: FormControl<TControl[K]> };

    public errors: { [K in keyof TControl]?: ValidatorError | null } | null = null;

    constructor(
        controls: {
            [K in keyof TControl]:
            TControl[K] |
            [TControl[K]] |
            [TControl[K], (ValidatorsFn | ValidatorsFn[])] |
            [TControl[K], (ValidatorsFn | ValidatorsFn[]), (AsyncValidatorFn | AsyncValidatorFn[])];
        },
    ) {
        super();
        if (typeof controls !== 'object') throw new Error('FormGroup value must be an object');
        const values: any = {};
        this.controls = {} as any;
        for (const key in controls) {
            const control = controls[key];
            if (Array.isArray(control)) {
                const [value, validators, asyncValidators] = control;
                values[key] = value;
                this.controls[key] = new FormControl(value, validators, asyncValidators);
            } else {
                const value = control as any
                values[key] = value;
                this.controls[key] = new FormControl(value);
            }
            this.controls[key].name = key;
            this.controls[key].parent = this;
            this.controls[key].onChange.subscribe(value => {
                this.setValue({ [key]: value } as unknown as TControl);
            });
            values[key] = this.controls[key].value;
        }
        this.defaultValue = values as TControl;
        this.value = this.defaultValue;
        this.applyValidators();
    }

    public onUpdate() {
        const values: any = {};
        for (const key in this.controls) {
            values[key] = this.controls[key].value;
        }
        this.setValue(values);
    }

    public setValue(value: TControl, options: FormSetOptions = DEFAULT_SET_OPTIONS): void {
        if (typeof value !== 'object') throw new Error('FormGroup value must be an object');
        if (value === this.value) return;
        this.value = { ...this.value, ...value };

        this.applyValidators(options)

        if (options.emitChange) {
            this.onChange.next(this.value);
        }
    }

    public applyValidators(options: FormSetOptions = DEFAULT_SET_OPTIONS): void {
        const isValidation = []
        const isDirties = [];
        const isToucheds = [];
        const errors: { [K in keyof TControl]?: ValidatorError | null } = {};
        for (const key in this.value) {
            if (this.controls[key]) {
                isValidation.push(this.controls[key].isValid);
                isDirties.push(this.controls[key].isDirty);
                isToucheds.push(this.controls[key].isTouched);
                errors[key] = this.controls[key].errors;
            }
        }

        const oldStatus = this.status;
        this.isValid = isValidation.every(v => v);
        this.isInvalid = !this.isValid;
        this.status = this.isValid ? 'valid' : 'invalid';
        if (this.status !== oldStatus && options.emitStatus) this.onStatusChange.next(this.status);

        this.errors = errors;

        if (options.emitChange) {
            this.isDirty = isDirties.some(v => v);
            this.isTouched = isToucheds.some(v => v);
        }
    }

    public reset(defaultValue?: TControl, options: FormSetOptions = DEFAULT_SET_OPTIONS): void {
        if (defaultValue) this.defaultValue = defaultValue;
        this.setValue(this.defaultValue, options);
    }

}



import React from "react";
import Slider, { Settings } from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { commentsJson } from "./commentsItems"




class CommentsHome extends React.Component {

    private slideConfig: Settings = {
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        prevArrow: (<span className="slick-arrow left"><FontAwesomeIcon icon={faAngleLeft} /></span>),
        nextArrow: (<span className="slick-arrow right"><FontAwesomeIcon icon={faAngleRight} /></span>),
    }

    render() {
        return (
            <section className="comments">
                <div className="container">
                    <div className="row">
                        <div className="col col-lg-9 mx-auto">
                            <h3>¿Qué dicen nuestros colaboradores sobre trabajar en nuestro equipo?</h3>
                        </div>
                    </div>
                    <div className="row">
                            <Slider {...this.slideConfig} className="job-posts-carousel">
                                {
                                    commentsJson.map((comment, index) => (
                                        <div className="comment-item" key={index}>
                                            <div className="img-container">
                                                <img src={comment.img} alt="Integrante de selección" />
                                            </div>
                                            <p>{comment.comment}</p>
                                            <h4>{comment.name}</h4>
                                        </div>
                                    ))
                                }
                            </Slider>
                        </div>
                </div>
            </section>
        );
    }
}

export default CommentsHome;
export const formatNumber = (value: any, locale: string | string[], digitFormat: string) => {
    if (value === null || value === undefined) return '';
    if (typeof value === 'string') value = Number(value);
    if (locale === undefined || locale === null) locale = 'en-US';
    if (digitFormat === undefined || digitFormat === null) digitFormat = '1.0-0';

    const [minIntDig, fracDig] = digitFormat.split('.');

    if (!minIntDig || !fracDig) throw new Error('Invalid digit format');

    const [minFracDig, maxFracDig] = fracDig.split('-');

    if (!minFracDig || !maxFracDig) throw new Error('Invalid digit format');

    return new Intl.NumberFormat(locale, {
        style: 'decimal',
        useGrouping: true,
        minimumIntegerDigits: Number(minIntDig),
        minimumFractionDigits: Number(minFracDig),
        maximumFractionDigits: Number(maxFracDig)
    })
}

export const getNodeText = (node: any): string => {
    if (['string', 'number'].includes(typeof node)) return node;
    if (node instanceof Array) return node.map(getNodeText).join('');
    if (typeof node === 'object' && node) return getNodeText(node.props.children);
    return '';
};

export const jobsTypes: {[key :string]: string}[] = [
        { value:'fulltime' , label : 'Jornada Completa' },
        { value:'parttime' , label : 'Part Time' },
        { value:'hourly' , label : 'Por hora' },
        { value:'remote' , label : 'Teletrabajo' },
        { value:'young' , label : 'Jóvenes Profesionales' },
        { value:'internship' , label : 'Pasantía' }
    ]

export let jobPayment : {[key :string]: string}[] = []

export function getDepartments(data: any, key: 'departments' | 'professions') {
    let array: string[] = []
    data.forEach((item: any) => {
        if (key === "departments") {
            if (item.geolocations && item.geolocations.length > 0) {
                item.geolocations.forEach((geo: any) => {
                    let word = geo.display_name.split(',')[0]
                    if (!array.includes(word)) array.push(word)
                })
            }
        } else {
            if (item.professions && item.professions.length > 0) {
                item.professions.forEach((prof: string) => {
                    if (!array.includes(prof)) array.push(prof)
                })
            }
        }
    })
    return array
}
import React from 'react';
import { Link } from 'react-scroll';
import { FormControl } from '../../../../containers/form/common';
import Input from '../../../../containers/form/input';

interface props {
    search: (search: string) => void;
}

interface states {
    search: FormControl;
}

class SearchBox extends React.Component<props, states> {

    

    state: states = {
        search: new FormControl('')
    }

    onSubmit() {
        this.props.search(this.state.search.value);
    }

    onChange( value: string ) {
        if( ! value ) this.onSubmit();
    }

    render() {
        return (
                <section className="search-box">
                    <div className="container">
                        <div className="row">
                            <div className="col col-xl-10 offset-xl-1 col-lg-12">
                                <div className="row search-box-content">
                                    <div className="col col-lg-9 col-md-8 col-sm-7 col-12">
                                        <div className="search-box-input">
                                            <label htmlFor="search_keyword" className="form-label">Encontrá tu oportunidad ideal</label>
                                            <Input className="form-control" onChange={ this.onChange.bind(this) } control={ this.state.search } name='search_keyword' type='search' placeholder="Ejemplo: Supervisor/a de Ventas, Gerente de Finanzas" />
                                        </div>
                                    </div>
                                    <div className="col col-lg-3 col-md-4 col-sm-5 col-12">  
                                    <Link to="results" offset={-200}  spy={true} smooth={false}><button type="button" className="btn search-box-btn" onClick={ this.onSubmit.bind(this) }>Buscar Empleo</button></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
        );
    }
}

export default SearchBox;

import axios from 'axios';
import { iJobPosts } from '../interfaces/job-posts';
import { iOpportunities } from '../interfaces/opportunities';
import { IProfessions } from '../interfaces/professions';


export default class API {

    professions: IProfessions[] = [];

    // private static ProfessionsPath = 'https://api2.worcket.com/public/professions';
    private static BasePath = 'https://apigateway.worcket.com/public/company/opportunitiesbyname';

    static Paths = {
        Home: `${this.BasePath}/manpowergroup%20interno`,
        Professions: 'https://api2.worcket.com/public/professions',
        Mampower: `${this.BasePath}/manpower%20uruguay`,
        MampoweBusiness: `${this.BasePath}/manpower%20bp`,
        Experis: `${this.BasePath}/experis`,
    }

    static getHome = () => {
        return new Promise<iJobPosts[]>((resolve, reject) => {
            axios.get(this.Paths.Home)
                .then(res => resolve(res.data || []))
                .catch(err => reject(err));
        })
    }




    static getProfessions = () => {
        return new Promise<IProfessions[]>((resolve, reject) => {
            axios.get(this.Paths.Professions)
                .then(res => resolve(res.data ? res.data.reverse() : []))
                .catch(err => reject(err));
        })
    }

    static getMampoweBusiness = () => {

        return new Promise<iOpportunities[]>((resolve, reject) => {

            axios.get(this.Paths.MampoweBusiness).then(res => {
                return resolve(res.data || []);
            })
        })
    }

    static getMampower = () => {

        return new Promise<iOpportunities[]>((resolve, reject) => {

            axios.get(this.Paths.Mampower).then(res => {
                return resolve(res.data || []);
            })
        })
    }

    static getExperis = () => {
        return new Promise<iOpportunities[]>((resolve, reject) => {
            axios.get(this.Paths.Experis)
                .then(res => resolve(res.data || []))
                .catch(err => reject(err));
        })
    }

    static getBlog = () => {
        return new Promise((resolve, reject) => {
            axios.post('https://api.hubapi.com/content/api/v2/blog-posts?hapikey=1e1d1b1f-9e8d-4d7c-8c6b-7d2e8b8f7a1a')
                .then((res) => resolve(res))
                .catch(err => reject(err));
        })
    }

}
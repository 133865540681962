import React from 'react';
import AssessmentIcon from '../../../../assets/images/pages/assessment-icon.svg';
import ShareIcon from '../../../../assets/images/pages/share-icon.svg';

export default class Info extends React.Component {
    render(){
        return (
            <section className="info-section">
                <div className="container">
                    <div className="row">
                        <div className="col col-lg-10 offset-lg-1 col-md-12">
                            <div className="row">
                                <div className="col col-lg-6 col-12">
                                    <div className="info-content">
                                        <div>
                                            <div className="media">
                                                <img src={AssessmentIcon} style={{marginLeft:'8px'}} alt="image_not_found" />
                                            </div>
                                        </div>
                                        <p>Postulate a ofertas laborales que te interesan y se ajustan más a tu perfil y aspiraciones.</p>
                                    </div>
                                </div>
                                <div className="col col-lg-6 col-12">
                                    <div className="info-content">
                                        <div>
                                            <div className="media">
                                                <img src={ ShareIcon } alt="image_not_found" />
                                            </div>
                                        </div>
                                        <p>Compartí ofertas de empleo con amigos a través de las redes sociales.</p>
                                    </div>
                                </div>
                            </div>			
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

import React from "react";
import ManPowerSmall from "../../../../assets/images/pages/tech-support.jpg";
import ManPowerProSmall from "../../../../assets/images/pages/manpower_pro_small_bg.jpg";
import ManPower from "../../../../assets/images/logos/manpower.svg";
import ManPowerBg from "../../../../assets/images/logos/manpower_bp.svg";
import ExperiesSmall from "../../../../assets/images/pages/experis_small_bg.jpg";
import ExperiesLogo from "../../../../assets/images/logos/experis.svg";
import { Link } from "react-router-dom";
import Paths from "../../../../services/paths";

class CompaniesHome extends React.Component {

    render() {
        return (
            <section className="companies" id="CompaniesHome">
                <div className="row companies-title" >
                    <div className="col col-12 text-center">
                        <h3>Encontrá tu trabajo ideal</h3>
                        <div className="tag tag-green">Explorá y descubrí los perfiles más buscados por las empresas</div>
                    </div>
                </div>
                <div className="companies-content" >
                    <div className="companies-item-container">
                        <div className="company-logo">
                            <img src={ManPower} alt="Mapower" />
                        </div>
                        <div className="companies-item">
                            <div className="companies-img" style={{ backgroundImage: `url('${ManPowerSmall}')` }}></div>
                            <div className="companies-item-title">
                                <h4>Cargos operativos y administrativos</h4>
                                <Link reloadDocument to={Paths.ManPower}>
                                    <button>Ver vacantes</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="companies-item-container">
                        <div className="company-logo">
                            <img src={ManPowerBg} alt="Mapower" />
                        </div>
                        <div className="companies-item">
                            <div className="companies-img" style={{ backgroundImage: `url('${ManPowerProSmall}')` }}></div>
                            <div className="companies-item-title">
                                <h4>Mandos medios y directivos</h4>
                                <Link reloadDocument to={Paths.ManPowerBusiness}>
                                    <button>Ver vacantes</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="companies-item-container">
                        <div className="company-logo">
                            <img src={ExperiesLogo} alt="Mapower" />
                        </div>

                        <div className="companies-item">
                            <div className="companies-img" style={{ backgroundImage: `url('${ExperiesSmall}')`, backgroundSize: 'auto', backgroundPosition: 'bottom left' }}></div>
                            <div className="companies-item-title">
                                <h4>Cargos de tecnología</h4>
                                <Link reloadDocument to={Paths.Experis}>
                                    <button>Ver vacantes</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default CompaniesHome;

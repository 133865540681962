import React from "react";
import Snapshot from '../../../../assets/video/snapshot.jpg';
import MainBGmp4 from '../../../../assets/video/main-bg.mp4';
import MainBGwebm from '../../../../assets/video/main-bg.webm';
import { Link } from "react-scroll";

class HeaderHome extends React.Component {

    render() {
        return (
            <section className="main-hero">
                <div className="video-container">
                    <div className="overlay"></div>
                    <video autoPlay muted loop poster={Snapshot}>
                        <source src={MainBGmp4} type="video/mp4"/>Your browser does not support the video tag. I suggest you upgrade your browser.
                        <source src={MainBGwebm} type="video/webm"/>Your browser does not support the video tag. I suggest you upgrade your browser.
                    </video>
                    {/* <div className="poster d-md-none"></div> */}
                </div>
                <div className="main-hero-container">
                    <div className="full-container">
                        <div className="row">
                            <div className="col col-12">
                                <div className="main-hero-content">
                                    <h2 className="hero-title">Llevá tu talento al siguiente nivel</h2>
                                    <p className="hero-subtitle">Conectá con las mejores oportunidades</p>
                                    <button className="btn hero-btn" onClick={this.onHandleClick.bind(this)}><Link to="CompaniesHome" offset={-100}  spy={true} smooth={false}>Quiero Ver Empleos</Link></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }

    private onHandleClick(event: { preventDefault: () => void; }){
        event.preventDefault();

        const companies = document.querySelector('.companies');
        if(companies){
            companies.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
        }
    }
}

export default HeaderHome;
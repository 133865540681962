import React, { ChangeEvent } from "react";
import { FormControl, ValidatorsFn } from "../common";
import './index.scss';

interface Props<TValue = any> {
    /** Obtiene el controlador del input */
    control: FormControl<TValue>,
    /** Funciones de Validación */
    validators?: ValidatorsFn | ValidatorsFn[];
    /** Referencia del Input */
    inputRef?: React.Ref<HTMLInputElement>;
    /** Nombre del input */
    name?: string;
    /** Tipo de Input */
    type?: 'text' | 'number' | 'email' | 'password' | 'search' | 'tel' | 'url' | 'hidden';
    /** Placeholder */
    placeholder?: string;
    /** Determina si el input esta deshabilitado */
    disabled?: boolean;
    /** Determina si el input esta solo lectura */
    readOnly?: boolean;
    /** Clases CSS */
    className?: string;
    /** Evento que se ejecuta cuando hay un cambio */
    onChange?: (value: TValue) => void;
    /** Evento que se ejecuta cuando hay un cambio de estado */
    onStatus?: (status: 'valid' | 'invalid') => void;
}

interface State<TValue = any>{
    /** Valor del input */
    value: TValue;
    /** Estado del input */
    status: 'valid' | 'invalid';
}

class Input<TValue = any> extends React.Component<Props<TValue>, State<TValue>> {

    readonly state = {
        value: this.props.control.value,
        status: this.props.control.status,
    }

    componentDidMount() {
        const { control, disabled, name, validators } = this.props;
        if (validators) {
            control.addValidators(validators);
            control.applyValidators();
        }
        if (disabled === undefined) disabled ? control.disable() : control.enable();
        if (name) control.name = name;
    }

    render() {
        const { control, type, readOnly, className, inputRef, placeholder } = this.props;
        const { name, isDisabled } = control
        const { value } = this.state;

        return (
            <input 
                ref={inputRef}
                className={className}
                type={type || 'text'} 
                name={name} 
                id={name} 
                placeholder={placeholder || ''}
                value={String(value) || ''}
                disabled={isDisabled}
                readOnly={readOnly !== undefined ? readOnly : false}
                onChange={this.handleChange.bind(this)}
            />
        );
    }

    private handleChange($event: ChangeEvent<HTMLInputElement>) {
        const { control, onChange, onStatus } = this.props;
        
        if(control.isDisabled) return;

        const value = $event.target.value as unknown as TValue;
        const { value: oldValue, status: oldStatus } = this.state;
        
        if (value === oldValue) return;
        
        control.setValue(value);
        this.setState({ value });
        
        if (oldStatus !== control.status) {
            this.setState({ status: control.status });
            if(onStatus) onStatus(control.status);
        }

        if(onChange) onChange(value);
    }
}

export default Input;

import React from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ImageBG from '../../assets/images/pages/contacto-img-03.jpg';
import './index.scss'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import SendEmail, { IBody } from '../../services/email/sendEmail';


interface Props {
    /** Muestra el modal */
    isShowModal: boolean;
    /** Nombre de la página */
    pageName: 'Home' | 'Experis' | 'Manpower BP' | 'Manpower';
    /** Cierra el modal */
    onCloseModal: () => void;
}
interface States {
    isShowModal: boolean
    isSendingMessage: boolean
    values: any | null
    pageName: 'Home' | 'Experis' | 'Manpower BP' | 'Manpower';
}


class ContactModal extends React.Component<Props, States> {
    state = {
        isShowModal: this.props.isShowModal,
        isSendingMessage: false,
        pageName: this.props.pageName,
        values: {
            // mailTo:'',
            sector: '',
            name: '',
            phone: '',
            email: '',
            subject: '',
            message: ''
        }
    }

    constructor(props: any) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.sendEmail = this.sendEmail.bind(this);
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<States>, snapshot?: any): void {
        if (prevProps.isShowModal !== this.props.isShowModal) {
            this.setState({ isShowModal: this.props.isShowModal })
        }

    }

    handleChange(event: any, field: string) {
        this.setState({
            values: {
                ...this.state.values,
                [`${field}`]: event.target.value,
            }
        })
    }


    showToast = () => {
        const MySwal = withReactContent(Swal)

        MySwal.fire({
            title: 'Mensaje enviado',
            text: 'Su mensaje ha sido enviado correctamente',
            icon: 'success',
            position: 'top-end',
            toast: true,
            timer: 3000,
            showConfirmButton: false
        })
    }


    render() {

        const { isShowModal } = this.state

        return (
            <>
                <Modal size="lg" show={isShowModal} onHide={() => this.closeModal()}>
                    <Modal.Header closeButton>
                        <Modal.Title>Contacto</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="modal-lg fade modal show d-block p-l-0" style={{ backgroundImage: `url(${ImageBG})` }}>
                        <form>
                            <div className="modal-body py-4 px-5">
                                <p className="m-b-5">Por dudas o consultas complete el siguiente formulario, le responderemos con la mayor brevedad posible.
                                </p>
                                <p className="mb-4 font-italic">Importante: por esta vía no se reciben CV ni solicitudes de empleo.</p>
                                <div className="fmodal-body">
                                    <select value={this.state.values.sector} onChange={(e) => this.handleChange(e, 'sector')} className="p-2 form-select form-select-lg mb-3" defaultValue={'0'} >
                                        <option value="0" hidden>Área de Comunicación</option>
                                        <option value="Candidato">Soy candidato</option>
                                        <option value="Empresas">Soy empresa</option>
                                        <option value="Support">Soporte técnico</option>
                                    </select>
                                    <div className="form-floating md-form mb-3"><i className="fas fa-user prefix grey-text"></i>
                                        <input value={this.state.values.name} onChange={(e) => this.handleChange(e, 'name')} type="text" className="form-control" id="floatingInputName" placeholder="Juan Perez" /><label htmlFor="floatingInputName">Nombre: (*)</label></div>
                                    <div className="form-floating md-form mb-3"><i className="fas fa-user prefix grey-text"></i>
                                        <input value={this.state.values.phone} onChange={(e) => this.handleChange(e, 'phone')} type="text" className="form-control" id="floatingInputName" placeholder="Juan Perez" /><label htmlFor="floatingInputName">Teléfono: (*)</label></div>
                                    <div className="form-floating md-form mb-3"><i className="fas fa-user prefix grey-text"></i>
                                        <input value={this.state.values.email} onChange={(e) => this.handleChange(e, 'email')} type="text" className="form-control" id="floatingInputName" placeholder="Juan Perez" /><label htmlFor="floatingInputName">E-mail: (*)</label></div>
                                    <div className="form-floating md-form mb-3"><i className="fas fa-user prefix grey-text"></i>
                                        <input value={this.state.values.subject} onChange={(e) => this.handleChange(e, 'subject')} type="text" className="form-control" id="floatingInputName" placeholder="Juan Perez" /><label htmlFor="floatingInputName">Asunto: (*)</label></div>
                                    <div className="form-floating"><textarea value={this.state.values.message} onChange={(e) => this.handleChange(e, 'message')} className="form-control h-100" placeholder="Leave a comment here"
                                        id="floatingTextarea2" ></textarea><label
                                            htmlFor="floatingTextarea2">Mensaje: (*)</label></div>
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="formBtn" disabled={this.state.isSendingMessage} onClick={(e) => this.sendEmail(e)}>
                            {this.state.isSendingMessage ? 'Enviando...' : 'Enviar'}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }

    sendEmail = async (event: any) => {
        event.preventDefault();
        // Send email
        this.setState({ isSendingMessage: true });

        // Send email
        const { name, email, phone, subject, message, sector } = this.state.values;
        const body: IBody = {
            name,
            phone,
            subject,
            message,
            page: this.state.pageName,
            sector,
            email
        }
        await SendEmail.sendContact(body);

        this.showToast();
        this.setState({ isShowModal: false, isSendingMessage: false });
    }
    closeModal = () => {
        this.props.onCloseModal()
        this.setState({ isShowModal: false })
    }
}

export default ContactModal;
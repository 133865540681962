import React from 'react'
import peopleOfficeImage from '../../../../assets/images/pages/Video-ManpowerBP.jpg';

export default class CurriculumVitae extends React.Component {
    render() {
        return (
            <>
                <section className="send-cv">
                    <div className="container">
                        <div className="row">
                            <div className="col col-lg-12">
                                <h3>¿No encontraste oportunidades que coincidan con tu perfil?</h3>
                                <a href="https://apply.worcket.com/?id=9937529b-4e6a-4c97-98c7-8fa99735d4d0" target="_blank" className="btn" rel='noreferrer'>Cargá tu CV</a>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content-info">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col col-xl-5 col-lg-6 col-12">
                                <h3>Evolucioná con Manpower</h3>
                                <p>Cumplí tus metas y objetivos como profesional. Estamos convencidos de tu potencial de crecimiento.</p>
                                <a href="https://www.youtube.com/watch?v=c2d3GQMk3Kc" className="btn" target="_blank" rel='noreferrer'>Ver Video</a>
                            </div>
                            <div className="col col-xl-6 offset-xl-1 col-lg-5 offset-lg-1 col-12 d-none d-lg-block">
                                <div className="img-container">
                                    <img src={peopleOfficeImage} alt="image_not_found" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}
